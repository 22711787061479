import Image from "next/image";
import {transformImage} from "../../utils/StoryblokService";
import {storyblokEditable} from "@storyblok/react";

export default function InlineImage(props: any) {
    const blok = props.blok;

    return <div className={"relative "+props.blok.class}  {...storyblokEditable(blok)}>
        <Image
            src={transformImage(blok.image?.filename, blok.imagesize || "1200x1200")}
            className={blok?.image_class}
            alt={blok.image?.alt}
            width={1200}
            height={1200}
            loading={"lazy"}
        /></div>
}